// import React from 'react';
// import { useParams, Link } from 'react-router-dom';
// import { useSelector } from 'react-redux';
// import './Brandpage.css'

// const BrandPage = () => {
//   const { brand } = useParams();
//   const { products } = useSelector((state) => state.products);

//   const brandProducts = products.filter((product) => product.brand === brand);

//   return (
//     <div>
//       <section className='bp'>
//         <div className='bp-cont'>
//       <h1 className='bp-title'>{brand} Products</h1>
//       <div className="products-list">
//         {brandProducts.map((product) => (
//           <div className="product" key={product.id}>
//             <Link to={`/product/${product.id}`}>
//             <div className='bp-card'>
//               <div className='bp-card-img'>
//                 <div className='bp-img'>
//                  <img src={product.image}></img>
//                  </div>
//               </div>
//               <div className='bp-card-con'> 
//               <h3>{product.name}</h3>
//               <p>{product.description}</p>
//               <p>${product.price}</p>
//               </div>
//               </div>
//             </Link>
//           </div>
//         ))}
//       </div>
//       </div>
//       </section>

//     </div>
//   );
// };

// export default BrandPage;
import React, { useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import './Brandpage.css';

const BrandPage = () => {
  const { brand } = useParams();
  const { products } = useSelector((state) => state.products);

  const [priceRange, setPriceRange] = useState({ min: 0, max: 10000 });
  const [sortOrder, setSortOrder] = useState('');
  const [selectedRam, setSelectedRam] = useState('');
  const [selectedColor, setSelectedColor] = useState('');

  const brandProducts = products.filter((product) => product.brand === brand);

  const filteredProducts = brandProducts.filter((product) => 
    product.price >= priceRange.min && 
    product.price <= priceRange.max &&
    (selectedRam ? product.ram === selectedRam : true) &&
    (selectedColor ? product.color.includes(selectedColor) : true)
  );

  const sortedProducts = [...filteredProducts].sort((a, b) => {
    if (sortOrder === 'lowToHigh') return a.price - b.price;
    if (sortOrder === 'highToLow') return b.price - a.price;
    return 0;
  });

  return (
    <div className='bp-page'>
      <aside className='bp-filters'>
        <h3>Filter by Price</h3>
        <div className="range-container">
          <div className="range-values">
            <span>${priceRange.min}</span>
            <span>${priceRange.max}</span>
          </div>
          <input
            type="range"
            min="0"
            max="10000"
            value={priceRange.min}
            onChange={(e) => setPriceRange({ ...priceRange, min: e.target.value })}
          />
          
        </div>

        <h3>Filter by RAM</h3>
        <select value={selectedRam} onChange={(e) => setSelectedRam(e.target.value)}>
          <option value="">All</option>
          <option value="4GB">4GB</option>
          <option value="6GB">6GB</option>
          <option value="8GB">8GB</option>
          <option value="12GB">12GB</option>
        </select>

        <h3>Filter by Color</h3>
        <select value={selectedColor} onChange={(e) => setSelectedColor(e.target.value)}>
          <option value="">All</option>
          <option value="Black">Black</option>
          <option value="White">White</option>
          <option value="Blue">Blue</option>
          <option value="Red">Red</option>
          <option value="Green">Green</option>
        </select>

        <h3>Sort by Price</h3>
        <select value={sortOrder} onChange={(e) => setSortOrder(e.target.value)}>
          <option value="">Select</option>
          <option value="lowToHigh">Low to High</option>
          <option value="highToLow">High to Low</option>
        </select>
      </aside>

      <section className='bp'>
        <div className='bp-cont'>
          <h1 className='bp-title'>{brand} Products</h1>
          <div className="products-list">
            {sortedProducts.map((product) => (
              <div className="product" key={product.id}>
                <Link to={`/product/${product.id}`}>
                  <div className='bp-card'>
                    <div className='bp-card-img'>
                      <div className='bp-img'>
                        <img src={product.image} alt={product.name} />
                      </div>
                    </div>
                    <div className='bp-card-con'>
                      <h3>{product.name}</h3>
                      <p>{product.description}</p>
                      <p>${product.price}</p>
                    </div>
                  </div>
                </Link>
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};

export default BrandPage;
