import React from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { addToCart } from '../redux/actions/cartActions';
import { useNavigate } from 'react-router-dom';
import './mobiledetail.css'
const ProductDetailPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { products } = useSelector((state) => state.products);

  const product = products.find((product) => product.id === parseInt(id));

  if (!product) {
    return <p>Product not found</p>;
  }

  const handleAddToCart = () => {
    dispatch(addToCart(product));
  };

  const handleBuyNow = () => {
    dispatch(addToCart(product));
    navigate('/payment'); 
  };

  return (
    <div>
      <section className='det'>
        <div className='det-cont'>
          <div className='det-row'>
            <div className='det-col'>
              <div className='det-card'>
              <div className='det-img'>
                <img src={product.image}></img>
            </div>
            <div className='det-btn'>
            <button onClick={handleAddToCart} className='det-but'>Add to Cart</button>
            <button onClick={handleBuyNow} className='det-but' >Buy Now</button>
            </div>
            </div>
            </div>
            <div className='det-col'>
              <div className='det-card1'>
      <h1 className='det-h1'>{product.name}</h1>
      <p className='det-p'>{product.description}</p>
      <p className='det-price'>${product.price}</p>
      </div>
      </div>
      </div>
      </div>
      </section>
    </div>
  );
};

export default ProductDetailPage;
