
// import React from 'react';
// import { Link } from 'react-router-dom';
// import { useSelector } from 'react-redux';
// import './Home.css'
// import Slide from './slide'
// import sam from './images/sum1.png'
// import apple from './images/iph1.png'
// import redmi from './images/xiaomi.png'
// import oppo from './images/opp1.png'
// import vivo from './images/vivo.png'
// import inf from './images/in.png'
// import poco from './images/poco.png'
// import one from './images/oneplus.png'

// const brands = [
//   { name: 'Samsung', image:sam },
//   { name: 'Apple', image:apple },
//   { name: 'Xiaomi', image:redmi },
//   { name: 'Oppo', image: oppo },
//   { name: 'Vivo', image:vivo },
//   { name: 'Infinix', image:inf },
//   { name: 'Poco', image:poco },
//   { name: 'OnePlus', image:one }

// ];

// const HomePage = () => {
//   const { products } = useSelector((state) => state.products);

//   const getTopProducts = (brand) => {
//     return products.filter((product) => product.brand === brand).slice(0, 5);
//   };

//   return (
//     <div>
//      <Slide/>
//      <section className='home-brand'> 
//       <div className='home-brand-cont'>
//       <div className="brand-list">
//         {brands.map((brand) => (
//           <div key={brand.name} className="brand-item">
//             <Link to={`/brand/${brand.name}`}>
//               <img src={brand.image} alt={brand.name} className="brand-image" />
//               <span>{brand.name}</span>
//             </Link>
//           </div>
//         ))}
//       </div>
//       </div>
//       </section>
//       <section className='home-pro'>
//         <div className='home-pro-cont'>
//       {brands.map((brand) => (
//         <div key={brand.name}>
//           <h2>{brand.name}</h2>
//           <div className="products-list">
//             {getTopProducts(brand.name).map((product) => (
//               <div className="product" key={product.id}>
//                 <Link to={`/product/${product.id}`}>
//                 <div className='pro-card'>
//                 <img src={product.image}></img>
//                   <h3 className='pro-h'>{product.name}</h3>
//                   <p className='pro-p'>{product.price} Rs</p>
//                   </div>
//                 </Link>
//               </div>
//             ))}
//           </div>
//         </div>

//       ))}
//       </div>
//       </section>
//     </div>
//   );
// };

// export default HomePage;
// import React from 'react';
// import { Link } from 'react-router-dom';
// import { useSelector } from 'react-redux';
// import Slider from "react-slick";
// import './Home.css';
// import Slide from './slide';
// import sam from './images/sum1.png';
// import apple from './images/iph1.png';
// import redmi from './images/xiaomi.png';
// import oppo from './images/opp1.png';
// import vivo from './images/vivo.png';
// import inf from './images/in.png';
// import poco from './images/poco.png';
// import one from './images/oneplus.png';

// const brands = [
//   { name: 'Samsung', image: sam },
//   { name: 'Apple', image: apple },
//   { name: 'Xiaomi', image: redmi },
//   { name: 'Oppo', image: oppo },
//   { name: 'Vivo', image: vivo },
//   { name: 'Infinix', image: inf },
//   { name: 'Poco', image: poco },
//   { name: 'OnePlus', image: one }
// ];

// const CustomPrevArrow = (props) => {
//   const { className, style, onClick } = props;
//   return (
//     <div
//       className={className}
//       style={{ ...style, display: 'block', left: '-25px', zIndex: 1 }}
//       onClick={onClick}
//     >
//       <i className="fa fa-chevron-left"></i>
//     </div>
//   );
// };

// const CustomNextArrow = (props) => {
//   const { className, style, onClick } = props;
//   return (
//     <div
//       className={className}
//       style={{ ...style, display: 'block', right: '-25px', zIndex: 1 }}
//       onClick={onClick}
//     >
//       <i className="fa fa-chevron-right"></i>
//     </div>
//   );
// };

// const HomePage = () => {
//   const { products } = useSelector((state) => state.products);

//   const getTopProducts = (brand) => {
//     return products.filter((product) => product.brand === brand).slice(0, 5);
//   };

//   const settings = {
//     dots: true,
//     infinite: true,
//     speed: 500,
//     slidesToShow: 4,
//     slidesToScroll: 1,
//     autoplay: true,
//     autoplaySpeed: 5000,
//     nextArrow: <CustomNextArrow />,
//     prevArrow: <CustomPrevArrow />,
//     responsive: [
//       {
//         breakpoint: 1024,
//         settings: {
//           slidesToShow: 2,
//           slidesToScroll: 1,
//           autoplay: true,
//           autoplaySpeed: 5000,
//           nextArrow: <CustomNextArrow />,
//           prevArrow: <CustomPrevArrow />
//         }
//       },
//       {
//         breakpoint: 600,
//         settings: {
//           slidesToShow: 1,
//           slidesToScroll: 1,
//           autoplay: true,
//           autoplaySpeed: 5000,
//           nextArrow: <CustomNextArrow />,
//           prevArrow: <CustomPrevArrow />
//         }
//       }
//     ]
//   };

//   return (
//     <div>
//       <Slide />
//       <section className='home-brand'>
//         <div className='home-brand-cont'>
//           <div className="brand-list">
//             {brands.map((brand) => (
//               <div key={brand.name} className="brand-item">
//                 <Link to={`/brand/${brand.name}`}>
//                   <img src={brand.image} alt={brand.name} className="brand-image" />
//                   <span>{brand.name}</span>
//                 </Link>
//               </div>
//             ))}
//           </div>
//         </div>
//       </section>

//       <section className='home-pro'>
//         <div className='home-pro-cont'>
//           {brands.map((brand) => (
//             <div key={brand.name}>
//               <h2>{brand.name}</h2>
//               <Slider {...settings}>
//                 {getTopProducts(brand.name).map((product) => (
//                   <div className="product" key={product.id}>
//                     <Link to={`/product/${product.id}`}>
//                       <div className='pro-card'>
//                         <img src={product.image} alt={product.name}></img>
//                         <h3 className='pro-h'>{product.name}</h3>
//                         <p className='pro-p'>{product.price} Rs</p>
//                       </div>
//                     </Link>
//                   </div>
//                 ))}
//               </Slider>
//               <Link to={`/brand/${brand.name}`} className="view-all">
//                 View All
//               </Link>
//             </div>
//           ))}
//         </div>
//       </section>
//     </div>
//   );
// };
  // <div className="product-card" key={product.id}>
                    //   <Link to={`/product/${product.id}`}>
                    //     <div className='pro-card'>
                    //       <img src={product.image} alt={product.name} className="product-image" />
                    //       <h3 className='pro-h'>{product.name}</h3>
                    //       <p className='pro-p'>{product.price} Rs</p>
                    //     </div>
                    //   </Link>
                    // </div>
// export default HomePage;

// import React from 'react';
// import { Link } from 'react-router-dom';
// import { useSelector } from 'react-redux';
// import Slider from "react-slick";
// import Slides from './slide'
// import lap from './images/phn.jpeg'
// import ph from './images/phh.jpeg'
// import tv from './images/tv.jpeg'
// import audio from './images/au.jpeg'
// import acc from './images/acc.jpeg'
// import './Home.css';
// import sam from './images/samss.png';
// import apple from './images/ipp.png';
// import redmi from './images/bd3.png';
// import oppo from './images/bd11.png';
// import vivo from './images/bd7.png';
// import inf from './images/bd6.png';
// import poco from './images/bd4.png';
// import one from './images/bd8.png';

// const brands = [
//   { name: 'Samsung', image: sam },
//   { name: 'Apple', image: apple },
//   { name: 'Xiaomi', image: redmi },
//   { name: 'Oppo', image: oppo },
//   { name: 'Vivo', image: vivo },
//   { name: 'Infinix', image: inf },
//   { name: 'Poco', image: poco },
//   { name: 'OnePlus', image: one }
// ];

// const HomePage = () => {
//   const { products } = useSelector((state) => state.products);
//   const getTopProducts = (brand) => {
//     return products.filter((product) => product.brand === brand);
//   };
//   const settings = {
//     dots: false,
//     infinite: true,
//     speed: 500,
//     slidesToShow: 4, 
//     slidesToScroll: 1, 
//     autoplay: true,
//     autoplaySpeed: 2000, 
//     arrows: true,
//     prevArrow: <button className="slick-prev">{"<"}</button>,
//     nextArrow: <button className="slick-next">{">"}</button>,
//   };
//   return (
//     <div>
//       <Slides />
//       <section className='cat'>
//         <div className='cat-cont'>
//           <div className='cat-row'>
//             <div className='cat-col'>
//               <div className='cat-card'>
//                 <img src={ph}></img>
//                 <p className='cat-p'>Mobiles</p>
//               </div>
//             </div>
//             <div className='cat-col'>
//               <div className='cat-card'>
//                 <img src={lap}></img>
//                 <p className='cat-p'>Laptops</p>
//               </div>
//             </div><div className='cat-col'>
//               <div className='cat-card'>
//                 <img src={tv}></img>
//                 <p className='cat-p'>Television</p>
//               </div>
//             </div><div className='cat-col'>
//               <div className='cat-card'>
//                 <img src={acc}></img>
//                 <p className='cat-p'>Accessories</p>
//               </div>
//             </div><div className='cat-col'>
//               <div className='cat-card'>
//                 <img src={audio}></img>
//                 <p className='cat-p'>Audio Systems</p>
//               </div>
//             </div>
//           </div>
//         </div>
//       </section>
//       <section className='home-brand'>
//         <div className='home-brand-cont'>
//           {brands.map((brand) => (
//             <div key={brand.name} className="brand-item">
//               <div className="brand-image-wrapper">
//                 <img src={brand.image} alt={brand.name} className="brand-image" />
//               </div>
//               <div className="slider-wrapper">
//                 <Slider {...settings}>
//                   {getTopProducts(brand.name).map((product) => (
                  
//                     <div className="product-card" key={product.id}>
//   <Link to={`/product/${product.id}`}>
//     <div className='pro-card'>
//       <div className='discount-tag'>20% OFF</div>
//       <img src={product.image} alt={product.name} className="product-image" />
//       <h3 className='pro-h'>{product.name}</h3>
//       <div className='price-wrapper'>
//         <p className='pro-p current-price'>{product.price} Rs</p>
//         <p className='pro-p actual-price'>10000 rs</p>
//       </div>
//     </div>
//   </Link>
// </div>

//                   ))}
//                 </Slider>
//                 <div className='view'>
//                   <Link to={`/brand/${brand.name}`} className='view-all'>View All</Link>
//                 </div>
//               </div>
//             </div>
//           ))}
//         </div>
//       </section>
//     </div>
//   );
// };

// export default HomePage;
// import React from 'react';
// import { Link } from 'react-router-dom';
// import { useSelector } from 'react-redux';
// import Slider from "react-slick";
// import Slides from './slide';
// import lap from './images/phn.jpeg';
// import ph from './images/phh.jpeg';
// import tv from './images/tv.jpeg';
// import audio from './images/au.jpeg';
// import acc from './images/acc.jpeg';
// import './Home.css';
// import sam from './images/samss.png';
// import apple from './images/ipp.png';
// import redmi from './images/bd3.png';
// import oppo from './images/bd11.png';
// import vivo from './images/bd7.png';
// import inf from './images/bd6.png';
// import poco from './images/bd4.png';
// import one from './images/bd8.png';

// const brands = [
//   { name: 'Samsung', image: sam },
//   { name: 'Apple', image: apple },
//   { name: 'Xiaomi', image: redmi },
//   { name: 'Oppo', image: oppo },
//   { name: 'Vivo', image: vivo },
//   { name: 'Infinix', image: inf },
//   { name: 'Poco', image: poco },
//   { name: 'OnePlus', image: one }
// ];

// const HomePage = () => {
//   const { products } = useSelector((state) => state.products);

//   const getTopProducts = (brand) => {
//     return products.filter((product) => product.brand === brand);
//   };

//   const settings = {
//     dots: false,
//     infinite: true,
//     speed: 500,
//     slidesToShow: 4,
//     slidesToScroll: 1,
//     autoplay: true,
//     autoplaySpeed: 2000,
//     arrows: true,
//     prevArrow: <button className="slick-prev">{"<"}</button>,
//     nextArrow: <button className="slick-next">{">"}</button>,
//   };

//   return (
//     <div>
//       <Slides />
//       <section className='cat'>
//         <div className='cat-cont'>
//           <div className='cat-row'>
//             <div className='cat-col'>
//               <div className='cat-card'>
//                 <img src={ph} alt="Mobiles" />
//                 <p className='cat-p'>Mobiles</p>
//               </div>
//             </div>
//             <div className='cat-col'>
//               <div className='cat-card'>
//                 <img src={lap} alt="Laptops" />
//                 <p className='cat-p'>Laptops</p>
//               </div>
//             </div>
//             <div className='cat-col'>
//               <div className='cat-card'>
//                 <img src={tv} alt="Television" />
//                 <p className='cat-p'>Television</p>
//               </div>
//             </div>
//             <div className='cat-col'>
//               <div className='cat-card'>
//                 <img src={acc} alt="Accessories" />
//                 <p className='cat-p'>Accessories</p>
//               </div>
//             </div>
//             <div className='cat-col'>
//               <div className='cat-card'>
//                 <img src={audio} alt="Audio Systems" />
//                 <p className='cat-p'>Audio Systems</p>
//               </div>
//             </div>
//           </div>
//         </div>
//       </section>
//       <section className='home-brand'>
//         <div className='home-brand-cont'>
//           {brands.map((brand) => (
//             <div key={brand.name} className="brand-item">
//               <div className="brand-image-wrapper">
//                 <img src={brand.image} alt={brand.name} className="brand-image" />
//               </div>
//               <div className="slider-wrapper">
//                 <Slider {...settings}>
//                   {getTopProducts(brand.name).map((product) => (
//                     <div className="product-card" key={product.id}>
//                       <Link to={`/product/${product.id}`}>
//                         <div className='pro-card'>
//                           <div className='discount-tag'>20% OFF</div>
//                           <img src={product.image} alt={product.name} className="product-image" />
//                           <h3 className='pro-h'>{product.name}</h3>
//                           <div className='price-wrapper'>
//                             <p className='pro-p current-price'>{product.price} Rs</p>
//                             <p className='pro-p actual-price'>10000 Rs</p>
//                           </div>
//                         </div>
//                       </Link>
//                     </div>
//                   ))}
//                   {/* Add the brand image as the last slide */}
//                   <div className="brand-image-slide">
//                     <img src={brand.image} alt={brand.name} className="brand-image" />
//                   </div>
//                 </Slider>
//                 <div className='view'>
//                   <Link to={`/brand/${brand.name}`} className='view-all'>View All</Link>
//                 </div>
//               </div>
//             </div>
//           ))}
//         </div>
//       </section>
//     </div>
//   );
// };

// export default HomePage;
import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Slider from "react-slick";
import Slides from './slide';
import lap from './images/phn.jpeg';
import ph from './images/phh.jpeg';
import tv from './images/tv.jpeg';
import audio from './images/au.jpeg';
import acc from './images/acc.jpeg';
import './Home.css';
import sam from './images/samss.png';
import apple from './images/ipp.png';
import redmi from './images/bd3.png';
import oppo from './images/bd11.png';
import vivo from './images/bd7.png';
import inf from './images/bd6.png';
import poco from './images/bd4.png';
import one from './images/bd8.png';
// Banner images for each brand
import banner1 from './images/vivoban.jpg';
import banner2 from './images/redmiban.jpg';
import iph1 from './images/ipban1.jpeg';
import iph2 from './images/iphban2.jpeg';
import sam1 from './images/samban1.jpeg';
import sam2 from './images/samban2.jpeg';
const brands = [
  { name: 'Samsung', image: sam },
  { name: 'Apple', image: apple },
  { name: 'Xiaomi', image: redmi },
  { name: 'Oppo', image: oppo },
  { name: 'Vivo', image: vivo },
  { name: 'Infinix', image: inf },
  { name: 'Poco', image: poco },
  { name: 'OnePlus', image: one }
];

// Array of objects with brand banners
const brandBanners = [
  { brand: 'Samsung', banners: [sam1, sam2] },
  { brand: 'Apple', banners: [iph1, iph2] },
  { brand: 'Xiaomi', banners: [banner1, banner2] },
  { brand: 'Oppo', banners: [banner1, banner2] },
  { brand: 'Vivo', banners: [banner1, banner2] },
  { brand: 'Infinix', banners: [banner1, banner2] },
  { brand: 'Poco', banners: [banner1, banner2] },
  { brand: 'OnePlus', banners: [banner1, banner2] }
];

const HomePage = () => {
  const { products } = useSelector((state) => state.products);

  const getTopProducts = (brand) => {
    return products.filter((product) => product.brand === brand);
  };

  const getBannersForBrand = (brand) => {
    const bannerObj = brandBanners.find((item) => item.brand === brand);
    return bannerObj ? bannerObj.banners : [];
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: true,
    prevArrow: <button className="slick-prev">{"<"}</button>,
    nextArrow: <button className="slick-next">{">"}</button>,
  };

  return (
    <div>
      <Slides />
      <section className='cat'>
        <div className='cat-cont'>
          <div className='cat-row'>
            <div className='cat-col'>
              <div className='cat-card'>
                <img src={ph} alt="Mobiles" />
                <p className='cat-p'>Mobiles</p>
              </div>
            </div>
            <div className='cat-col'>
              <div className='cat-card'>
                <img src={lap} alt="Laptops" />
                <p className='cat-p'>Laptops</p>
              </div>
            </div>
            <div className='cat-col'>
              <div className='cat-card'>
                <img src={tv} alt="Television" />
                <p className='cat-p'>Television</p>
              </div>
            </div>
            <div className='cat-col'>
              <div className='cat-card'>
                <img src={acc} alt="Accessories" />
                <p className='cat-p'>Accessories</p>
              </div>
            </div>
            <div className='cat-col'>
              <div className='cat-card'>
                <img src={audio} alt="Audio Systems" />
                <p className='cat-p'>Audio Systems</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      
      <section className='home-brand'>
        <div className='home-brand-cont'>
          {brands.map((brand) => (
            <div key={brand.name} className="brand-item">
              <div className="brand-image-wrapper">
                <img src={brand.image} alt={brand.name} className="brand-image" />
              </div>
              <div className="slider-wrapper">
                <Slider {...settings}>
                  {getTopProducts(brand.name).map((product) => (
                    <div className="product-card" key={product.id}>
                      <Link to={`/product/${product.id}`}>
                        <div className='pro-card'>
                          <div className='discount-tag'>20% OFF</div>
                          <img src={product.image} alt={product.name} className="product-image" />
                          <h3 className='pro-h'>{product.name}</h3>
                          <div className='price-wrapper'>
                            <p className='pro-p current-price'>{product.price} Rs</p>
                            <p className='pro-p actual-price'>10000 Rs</p>
                          </div>
                        </div>
                      </Link>
                    </div>
                  ))}
                </Slider>
                <div className='view'>
                  <Link to={`/brand/${brand.name}`} className='view-all'>View All</Link>
                </div>
              </div>
              
              {/* Add banners for each brand after the slider */}
              <section className="brand-banners">
                {getBannersForBrand(brand.name).map((banner, idx) => (
                  <img key={idx} src={banner} alt={`${brand.name} Banner ${idx + 1}`} className="brand-banner-image" />
                ))}
              </section>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
};

export default HomePage;
