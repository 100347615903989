import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { removeFromCart } from '../redux/actions/cartActions';
import { Link } from 'react-router-dom';
import { FaTimes } from 'react-icons/fa'; // Import the cross icon
import './Cart.css';

const Cart = () => {
  const cart = useSelector((state) => state.cart);
  const { cartItems } = cart;
  const dispatch = useDispatch();

  // Calculate total amount
  const totalAmount = cartItems.reduce((total, item) => total + item.price, 0);

  return (
    <div>
      <section className="carts">
        <div className='cart-cont'>
          <div className='cart-row'>
            {cartItems.length === 0 ? (
              <div>Your cart is empty</div>
            ) : (
              <div className='cart-col'>
                {cartItems.map((item) => (
                  <div key={item.id} className='cart-card'>
                    <div className='cart-im'>
                      <div className='cart-img'>
                        <img src={item.image} alt={item.name}></img>
                      </div>
                    </div>
                    <div className='cart-con'>
                      <h3>{item.name}</h3>
                      <p>${item.price}</p>
                    </div>
                    <button className='remove-btn' onClick={() => dispatch(removeFromCart(item.id))}>
                      <FaTimes />
                    </button>
                  </div>
                ))}
                <div className='total-amount'>
                  <h3>Total Amount: ${totalAmount.toFixed(2)}</h3>
                </div>
                <Link to="/payment" className='proceed-btn'>Proceed to Payment</Link>
              </div>
            )}
          </div>
        </div>
      </section>
    </div>
  );
};

export default Cart;
