import React from 'react';
import { useSelector } from 'react-redux';

const Payment = () => {
  const cart = useSelector((state) => state.cart);
  const { cartItems } = cart;

  const totalPrice = cartItems.reduce((acc, item) => acc + item.price, 0);

  return (
    <div className="payment">
      <h2>Payment</h2>
      <div>Total Price: ${totalPrice}</div>
      <button>Pay Now</button>
    </div>
  );
};

export default Payment;
