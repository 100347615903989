
import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FaSearch, FaShoppingCart, FaUser, FaBars, FaHeart } from 'react-icons/fa';
import './Header.css';
import logo from './images/vv.png';

const Navbar = () => {
  const [isLoginPopupOpen, setIsLoginPopupOpen] = useState(false);
  const cart = useSelector((state) => state.cart);
  const { cartItems } = cart;
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState('');
  const products = useSelector((state) => state.products.products);
  const navigate = useNavigate();

  const handleSearch = (e) => {
    if (e.key === 'Enter' || e.type === 'click') {
      if (searchTerm) {
        const filteredProducts = products.filter(product =>
          product.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          product.brand.toLowerCase().includes(searchTerm.toLowerCase())
        );
  
        if (filteredProducts.length > 0) {
          const firstProduct = filteredProducts[0];
          navigate(`/product/${firstProduct.id}`, { state: { product: firstProduct } });
        } else {
          alert('No products found');
        }
      }
    }
  };

  const handleAddToCart = () => {
    if (!isUserLoggedIn) {
      setIsLoginPopupOpen(true);
    } else {
      // Logic for adding to cart
    }
  };

  const handleBuyNow = () => {
    if (!isUserLoggedIn) {
      setIsLoginPopupOpen(true);
    } else {
      // Logic for buying now
    }
  };

  const isUserLoggedIn = false;

  return (
    <div className='na'>
      <nav className="navbar">
        <div className="logo">
          <Link to="/">
            <img src={logo} alt="VIS" />
          </Link>
        </div>

        <div className="dropdowns">
          <h1 className="dropbtns">   <FaBars className="burger-menu" /> Shop by Brands</h1>
          <div className="dropdown-contents">
                    <Link to="/brand/Samsung">Samsung</Link>
                    <Link to="/brand/Apple">Apple</Link>
                    <Link to="/brand/Xiaomi">Xiaomi</Link>
                    <Link to="/brand/Oppo">Oppo</Link>
                    <Link to="/brand/Vivo">Vivo</Link>
                    <Link to="/brand/Infinix">Infinix</Link>
                    <Link to="/brand/Poco">Poco</Link>
                    <Link to="/brand/OnePlus">OnePlus</Link>
                  </div>
        </div>

        <div className="search-container">
          <input
            className="search-input"
            type="text"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            onKeyDown={handleSearch}
            placeholder="Search for products"
          />
          <FaSearch className="search-icon" onClick={handleSearch} />
        </div>

        <div className="cart">
          <Link to="/cart">
            <FaShoppingCart className='na-ic' />
            <sup>{cartItems.length}</sup>
          </Link>
          <FaHeart className="wishlist-icon" /> 
          <FaUser className='na-ic' onClick={() => setIsLoginPopupOpen(true)} />
        </div>
      </nav>
      
      <section className='sub-nav'>
        <div className='sub-nav-cont'>
          <div className='sub-nav-row'>
            <div className='sub-nav-col'>
              <div className='sub-nav-card'>
                <div className="dropdown">
                  <h1 className="dropbtn">Mobiles</h1>
                  <div className="dropdown-content">
                    <Link to="/brand/Samsung">Samsung</Link>
                    <Link to="/brand/Apple">Apple</Link>
                    <Link to="/brand/Xiaomi">Xiaomi</Link>
                    <Link to="/brand/Oppo">Oppo</Link>
                    <Link to="/brand/Vivo">Vivo</Link>
                    <Link to="/brand/Infinix">Infinix</Link>
                    <Link to="/brand/Poco">Poco</Link>
                    <Link to="/brand/OnePlus">OnePlus</Link>
                  </div>
                </div>
              </div>
            </div>

            {/* Repeat this block for each category you want in the sub-nav */}
            <div className='sub-nav-col'>
              <div className='sub-nav-card'>
                <div className="dropdown">
                  <h1 className="dropbtn">Laptops</h1>
                  <div className="dropdown-content">
                    <Link to="/brand/Samsung">Samsung</Link>
                    <Link to="/brand/Apple">Apple</Link>
                    <Link to="/brand/Xiaomi">Xiaomi</Link>
                    <Link to="/brand/Oppo">Oppo</Link>
                    <Link to="/brand/Vivo">Vivo</Link>
                    <Link to="/brand/Infinix">Infinix</Link>
                    <Link to="/brand/Poco">Poco</Link>
                    <Link to="/brand/OnePlus">OnePlus</Link>
                  </div>
                </div>
              </div>
            </div>
 
                   <div className='sub-nav-col'>
              <div className='sub-nav-card'>
                <div className="dropdown">
                  <h1 className="dropbtn">Television</h1>
                  <div className="dropdown-content">
                    <Link to="/brand/Samsung">Samsung</Link>
                    <Link to="/brand/Apple">Apple</Link>
                    <Link to="/brand/Xiaomi">Xiaomi</Link>
                    <Link to="/brand/Oppo">Oppo</Link>
                    <Link to="/brand/Vivo">Vivo</Link>
                    <Link to="/brand/Infinix">Infinix</Link>
                    <Link to="/brand/Poco">Poco</Link>
                    <Link to="/brand/OnePlus">OnePlus</Link>
                  </div>
                </div>
              </div>
            </div>         
                      <div className='sub-nav-col'>
              <div className='sub-nav-card'>
                <div className="dropdown">
                  <h1 className="dropbtn">Accessories</h1>
                  <div className="dropdown-content">
                    <Link to="/brand/Samsung">Samsung</Link>
                    <Link to="/brand/Apple">Apple</Link>
                    <Link to="/brand/Xiaomi">Xiaomi</Link>
                    <Link to="/brand/Oppo">Oppo</Link>
                    <Link to="/brand/Vivo">Vivo</Link>
                    <Link to="/brand/Infinix">Infinix</Link>
                    <Link to="/brand/Poco">Poco</Link>
                    <Link to="/brand/OnePlus">OnePlus</Link>
                  </div>
                </div>
              </div>
            </div>            <div className='sub-nav-col'>
              <div className='sub-nav-card'>
                <div className="dropdown">
                  <h1 className="dropbtn">Audio Systems</h1>
                  <div className="dropdown-content">
                    <Link to="/brand/Samsung">Samsung</Link>
                    <Link to="/brand/Apple">Apple</Link>
                    <Link to="/brand/Xiaomi">Xiaomi</Link>
                    <Link to="/brand/Oppo">Oppo</Link>
                    <Link to="/brand/Vivo">Vivo</Link>
                    <Link to="/brand/Infinix">Infinix</Link>
                    <Link to="/brand/Poco">Poco</Link>
                    <Link to="/brand/OnePlus">OnePlus</Link>
                  </div>
                </div>
              </div>
            </div>

            {/* Add other categories as needed */}
          </div>
        </div>
      </section>
    </div>
  );
};

export default Navbar;
