import React from 'react';
import { FaFacebookF, FaInstagram, FaYoutube, FaCcVisa, FaCcMastercard, FaCcAmex, FaCcPaypal, FaCcDiscover } from 'react-icons/fa';
import './Footer.css';

const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer-container">
                <div className="footer-about">
                    <h2>About Us</h2>
                    <p>
                        Alagu Mobiles, founded in 2003 by the dynamic and visionary Managing Director,
                        has been a formidable player in the mobile retail industry. Based in [Your Location],
                        our journey has been marked by innovation, expansion, and a relentless commitment to customer satisfaction.
                    </p>
                    <img src="path_to_logo" alt="Alagu Mobiles Logo" className="footer-logo" />
                </div>
                <div className="footer-links">
                    <h2>Collections</h2>
                    <ul>
                        <li>Mobile & Tablet</li>
                        <li>Computers & Laptops</li>
                        <li>Accessories</li>
                        <li>Audio Systems</li>
                        <li>Television</li>
                    </ul>
                </div>

                <div className="footer-contacts">
                    <h2>Contacts</h2>
                    <p>9944412345</p>
                    <p>info@alagumobiles.com</p>
                    <p>
                        No.666, main Road, Near Anna Selai, Surendai,
                         Tamil Nadu - 641 044
                    </p>
                    <div className="social-icons">
                        <FaFacebookF size={24} />
                        <FaInstagram size={24} />
                        <FaYoutube size={24} />
                    </div>
                </div>
            </div>
            <div className="footer-bottom">
                <div className="footer-payment-options">
                    <FaCcVisa size={32} />
                    <FaCcMastercard size={32} />
                    <FaCcAmex size={32} />
                    <FaCcPaypal size={32} />
                    <FaCcDiscover size={32} />
                </div>
                <div className="footer-emi-partners">
                    <img src="path_to_hdfc_logo" alt="HDFC Bank" className="emi-logo" />
                    <img src="path_to_bajaj_logo" alt="Bajaj Finserv" className="emi-logo" />
                    <img src="path_to_homecredit_logo" alt="Home Credit" className="emi-logo" />
                </div>
            </div>
        </footer>
    );
};

export default Footer;
