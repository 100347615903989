import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './redux/store';
import Header from './components/Header';
import Footer from './components/Footer';

import Home from './components/Home';
import BrandPage from './components/Brandpage';
import MobileDetail from './components/mobileDetails';
import Cart from './components/Cart';
import Payment from './components/Payment';
import LoginPage from './components/loginpage';

const App = () => {
  return (
    <Provider store={store}>
      <Router>
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/brand/:brand" element={<BrandPage/>} />
          <Route path="/product/:id" element={<MobileDetail />} />
          <Route path="/cart" element={<Cart />} />
          <Route path="/payment" element={<Payment />} />
          <Route path="/login" element={<LoginPage />} />
        </Routes>
        <Footer/>
      </Router>
    </Provider>
  );
};

export default App;
