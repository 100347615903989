import image from './image/ip15.jpg'

import s1 from './image/s1.jpg'
import s2 from './image/s2.jpg'
import s3 from './image/s3.jpg'
import s4 from './image/sga52.jpg'
import s5 from './image/sga32.jpg'
import s6 from './image/Samsung Galaxy Z Fold 3.jpeg'
import s7 from './image/Samsung Galaxy Z Flip 6.jpeg'
import s8 from './image/s8.jpg'
import s9 from './image/sgm42.jpg'
import s10 from './image/sga72.jpg'
import s11 from './image/s11.jpg'
import s12 from './image/sga12.jpg'
import s13 from './image/Samsung Galaxy F42 5G.jpg'
import s14 from './image/SAMSUNG Galaxy Note 20 Ultra 5G.jpg'
import s15 from './image/Samsung Galaxy S24 Ultra.jpg'


import ip1 from './image/i 1.jpg'
import ip2 from './image/i2.jpg'
import ip3 from './image/i 3.jpg'
import ip4 from './image/i4.jpg'
import ip5 from './image/i5.jpg'
import ip6 from './image/i 6.jpg'
import ip7 from './image/i7.jpg'
import ip8 from './image/i8.jpg'
import ip9 from './image/i 9.jpg'
import ip10 from './image/i10.jpg'
import ip11 from './image/i 11.jpg'
import ip12 from './image/i 12.jpg'
import ip13 from './image/i 13.jpg'
import ip14 from './image/i 14.jpg'
import ip15 from './image/i 15.jpg'

import i1 from './image/Infinix Zero 8.jpg'
import i2 from './image/Infinix Zero 8i.jpg'
import i3 from './image/Infinix Hot 40i.jpg'
import i4 from './image/Infinix Note 40 Pro 5G.jpg'
import i5 from './image/Infinix GT 20 Pro.jpg'
import i6 from './image/Infinix SMART 8 HD.jpg'
import i7 from './image/Infinix Hot 30 5G.jpg'
import i8 from './image/Infinix Hot 11.jpeg'
import i9 from './image/Infinix HOT 12.jpg'
import i10 from './image/Infinix Smart 7 HD.jpg'
import i11 from './image/Infinix Zero 30 5G.jpg'
import i12 from './image/Infinix Note 40 Pro+ 5G.jpg'
import i13 from './image/izu.jpg'
import i14 from './image/i30.jpg'
import i15 from './image/Infinix Note 30.jpg'


import o1 from './image/0 1.jpg'
import o2 from './image/o 2.jpg'
import o3 from './image/o3.jpg'
import o4 from './image/o 4.jpg'
import o5 from './image/o5.jpg'
import o6 from './image/o6.jpg'
import o7 from './image/o 7.jpg'
import o8 from './image/o 8.jpg'
import o9 from './image/o 9.jpg'
import o10 from './image/o 10.jpg'
import o11 from './image/o 11.jpg'
import o12 from './image/o 12.jpg'
import o13 from './image/o 13.jpg'
import o14 from './image/o 14.jpg'
import o15 from './image/o 15.jpg'

import r1 from './image/Redmi Note 11.jpg'
import r2 from './image/Redmi Note 11 Pro.jpg'
import r3 from './image/Redmi Note 11 Pro+.jpg'
import r4 from './image/Redmi K40.jpg'
import r5 from './image/Redmi K40 Pro+.jpg'
import r6 from './image/Redmi K40.jpg'
import r7 from './image/Redmi 10.jpg'
import r8 from './image/Redmi 10 Prime (2).jpg'
import r9 from './image/Redmi 10x.jpg'
import r10 from './image/Redmi 10X Pro.jpg'
import r11 from './image/Redmi Note 10.jpg'
import r12 from './image/Redmi Note 10 Pro.jpg'
import r13 from './image/Redmi Note 10 Pro Max.jpg'
import r14 from './image/Redmi 12 5G.jpg'
import r15 from './image/Redmi 13 5G.jpg'


import v1 from './image/vivo Y18i.jpg'
import v2 from './image/Vivo X70 Pro.jpg'
import v3 from './image/vivo Y200 Pro 5G.jpg'
import v4 from './image/vivo Y22.jpg'
import v5 from './image/Vivo T3 Lite 5G Smartphone.jpg'
import v6 from './image/Vivo Y200e 5G.jpg'
import v7 from './image/vivo Y58 5G.jpg'
import v8 from './image/vivo Y28e 5G.jpg'
import v9 from './image/Vivo V30e 5G Smartphone.jpg'
import v10 from './image/Vivo T2x 5G.jpg'
import v11 from './image/Vivo Y27.jpg'
import v12 from './image/Vivo V29e 5G.jpg'
import v13 from './image/Vivo Y200e 5G.jpg'
import v14 from './image/vivo V29 Pro 5G.jpg'
import v15 from './image/Vivo X100 5G.jpg'

import p1 from './image/poco 3.jpg'
import p2 from './image/poco 3.jpg'
import p3 from './image/poco 3.jpg'
import p4 from './image/poco 4.jpg'
import p5 from './image/poco 5.jpg'
import p6 from './image/poco 6.jpg'
import p7 from './image/poco 7.jpg'
import p8 from './image/poco 8.jpg'
import p9 from './image/poco 9.jpg'
import p10 from './image/poco 10.jpg'
import p11 from './image/poco 11.jpg'
import p12 from './image/poco 12.jpg'
import p13 from './image/poco 13.jpg'
import p14 from './image/poco 14.jpg'
import p15 from './image/poco 15.jpg'


import on1 from './image/0ne plus 1.jpg'
import on2 from './image/0ne plus 2.jpg'
import on3 from './image/0ne plus 3.jpg'
import on4 from './image/0ne plus 4.jpg'
import on5 from './image/0ne plus 5.jpg'
import on6 from './image/0ne plus 6.jpg'
import on7 from './image/0ne plus 7.jpg'
import on8 from './image/0ne plus 8.jpg'
import on9 from './image/0ne plus 9.jpg'
import on10 from './image/0ne plus 10.jpg'
import on11 from './image/0ne plus 11.jpg'
import on12 from './image/0ne plus 12.jpg'
import on13 from './image/0ne plus 13.jpg'
import on14 from './image/0ne plus 14.jpg'
import on15 from './image/0ne plus 15.jpg'



const initialState = {
    products: [
        // Samsung Phones
        { id: 1, name: 'Samsung Galaxy S21', price: 999, description: 'Latest Samsung mobile', brand: 'Samsung',image:s1 },
        { id: 2, name: 'Samsung Galaxy S20', price: 899, description: 'Previous model Samsung mobile', brand: 'Samsung',image:s2 },
        { id: 3, name: 'Samsung Galaxy Note 20', price: 950, description: 'High-end Samsung mobile', brand: 'Samsung',image:s3 },
        { id: 4, name: 'Samsung Galaxy A52', price: 499, description: 'Mid-range Samsung mobile', brand: 'Samsung',image:s4  },
        { id: 5, name: 'Samsung Galaxy A32', price: 350, description: 'Budget Samsung mobile', brand: 'Samsung' ,image:s5 },
        { id: 6, name: 'Samsung Galaxy Z Fold 3', price: 1799, description: 'Foldable Samsung mobile', brand: 'Samsung',image:s6  },
        { id: 7, name: 'Samsung Galaxy Z Flip 3', price: 999, description: 'Compact foldable Samsung mobile', brand: 'Samsung',image:s7},
        { id: 8, name: 'Samsung Galaxy M32', price: 299, description: 'Entry-level Samsung mobile', brand: 'Samsung',image:s8},
        { id: 9, name: 'Samsung Galaxy M42', price: 399, description: '5G Samsung mobile', brand: 'Samsung',image:s9},
        { id: 10, name: 'Samsung Galaxy A72', price: 549, description: 'High-end mid-range Samsung mobile', brand: 'Samsung' ,image:s10},
        { id: 11, name: 'Samsung Galaxy S21 FE', price: 699, description: 'Fan Edition Samsung mobile', brand: 'Samsung',image:s11  },
        { id: 12, name: 'Samsung Galaxy A12', price: 249, description: 'Affordable Samsung mobile', brand: 'Samsung',image:s12 },
        { id: 13, name: 'Samsung Galaxy A42 5G', price: 429, description: '5G Samsung mobile', brand: 'Samsung',image:s13 },
        { id: 14, name: 'Samsung Galaxy Note 10', price: 749, description: 'Previous generation flagship', brand: 'Samsung',image:s14  },
        { id: 15, name: 'Samsung Galaxy S21 Ultra', price: 1199, description: 'Premium Samsung mobile', brand: 'Samsung' ,image:s15},
      //   { id: 1, name: 'Samsung Galaxy S21',color:'Black',orginal_price:74,999, selling_price:25,999 ,offer:65%, description: 'Latest Samsung mobile', brand: 'Samsung',ram: '8GB', rom: '256', dispaly:'16.26 cm (6.4 inch) Full HD+ Display',battery: '4500 mAh Lithium-ion Battery', front_camera:'32MP' , back_camera:'12MP + 12MP + 8MP',
      //     image:"Samsung Galaxy S21", },
      // { id: 2, name: 'Samsung Galaxy S20',color:'Black', original_price:57,999, selling_price: 27,499,offer:54%, description: 'Previous model Samsung mobile', brand: 'Samsung',ram: '8GB', rom: '128GB', dispaly:'16.51 cm (6.5 inch) Display
      // ',battery: '4500 mAh Battery', front_camera:'32MP' , back_camera:'12MP Rear Camera',
      // image:"Samsung Galaxy S20"  },
      // { id: 3, name: 'Samsung Galaxy Note 20',color:'Mystic Green', orginal_price:86,000, selling_price: Sold Out,offer:, description: 'High-end Samsung mobile', brand: 'Samsung', ram: '8GB', rom: '256GB', dispaly:'17.02 cm (6.7 inch) Full HD+ Display',battery: '4300 mAh Lithium-ion Battery', front_camera:'10MP ' , back_camera:'64MP + 12MP + 12MP ',
      //     image:"Samsung Galaxy Note 20"  },
      // { id: 4, name: 'Samsung Galaxy A52',color:'Icy Blue', orginal_price:34,999, selling_price:22,968 ,offer:34%, description: 'Mid-range Samsung mobile', brand: 'Samsung',ram: '6GB', rom: '128GB', dispaly:'17.02 cm (6.7 inch) Display',battery: '5000 mAh', front_camera:'16MP' , back_camera:'64MP Rear Camera',
      //     image:"Samsung Galaxy A52"  },
      // { id: 5, name: 'Samsung Galaxy A32',color:'Awesome Blue', orginal_price:27,999, selling_price: 23,499,offer:16%, 'Budget Samsung mobile', brand: 'Samsung' ,ram: '8GB', rom: '128GB', dispaly:'16.26 cm (6.4 inch) Full HD+ Display',battery: '5000 mAh Lithium ion Battery', front_camera:' 20MP' , back_camera:'64MP + 8MP + 5MP + 5MP ',
      //     image:"Samsung Galaxy A32" },
      // { id: 6, name: 'Samsung Galaxy Z Fold 3',color:'Silver Shadow', orginal_price:1,64,999, selling_price:1,64,999 ,offer:,  description: 'Foldable Samsung mobile', brand: 'Samsung',ram: '12GB', rom: '256GB', dispaly:'19.3 cm (7.6 inch) QXGA+ Display',battery: '4400 mAh Lithium ion Battery', front_camera:'10MP' , back_camera:'50MP + 12MP + 10MP ',
      //     image:"Samsung Galaxy Z Fold 3"  },
      //         { id: 7, name: 'Samsung Galaxy Z Flip 6',color:'Mint', orginal_price:1,09,999, selling_price:1,09,999,offer:, description: 'Compact foldable Samsung mobile', brand: 'Samsung',ram: '12GB', rom: '256GB', dispaly:'17.02 cm (6.7 inch) Full HD+ Display',battery: '4000 mAh Lithium ion Battery', front_camera:'10MP' , back_camera:'50MP + 12MP',
      //             image:"Samsung Galaxy Z Flip 6"  },
      //         { id: 8, name: 'Samsung Galaxy M32',color:'Black', orginal_price:18999, selling_price:12,990,offer:31%, description: 'Entry-level Samsung mobile', brand: 'Samsung',ram: '6GB', rom: '128GB', dispaly:'16.26 cm (6.4 inch) Display',battery: '6000 mAh Battery', front_camera:'12MP' , back_camera:'64MP Rear Camera',
      //             image:"Samsung Galaxy M32" },
      //         { id: 9, name: 'Samsung Galaxy M42',color:'Prism Dot Gray', orginal_price:24999, selling_price:20,909,offer:16% description: '5G Samsung mobile', brand: 'Samsung',ram: '6GB', rom: '126GB', dispaly:'16.76 cm (6.6 inch) HD+ Display',battery: '5000 mAh Battery', front_camera:'12MP' , back_camera:'48MP Rear Camera',
      //             image:"Samsung Galaxy M42"  },
      //         { id: 10, name: 'Samsung Galaxy A72',color:'Awesome Violet', orginal_price:41999, selling_price:41,999,offer:, description: 'High-end mid-range Samsung mobile', brand: 'Samsung' ,ram: '8GB', rom: '128GB', dispaly:'17.02 cm (6.7 inch) Full HD+ Display',battery: '5000 mAh Lithium-ion Battery', front_camera:'32MP' , back_camera:'64MP + 12MP + 8MP + 5MP',
      //             image:"Samsung Galaxy A72" },
      //         { id: 11, name: 'Samsung Galaxy S21 FE',color:'Graphite', orginal_price:74999, selling_price:28,485,offer:62%, description: 'Fan Edition Samsung mobile', brand: 'Samsung',ram: '8GB', rom: '256GB', dispaly:'16.26 cm (6.4 inch) Full HD+ Display',battery: '4500 mAh Lithium-ion Battery', front_camera:'32MP' , back_camera:'12MP + 12MP + 8MP (OIS) ',
      //             image:"Samsung Galaxy S21 FE"  },
      //         { id: 12, name: 'Samsung Galaxy A12',color:'Black', orginal_price:17734, selling_price:14,174,offer:20%,description: 'Affordable Samsung mobile', brand: 'Samsung',ram: '6GB', rom: '128GB', dispaly:'16.51 cm (6.5 inch) HD+ Display',battery: '5000 mAh Battery', front_camera:'8MP' , back_camera:'48MP + 5MP + 2MP + 2MP',
      //             image:"Samsung Galaxy A12"  },
      //         { id: 13, name: 'Samsung Galaxy F42 5G',color:'Matte Black', orginal_price:23999, selling_price:20,999,offer:12%, description: '5G Samsung mobile', brand: 'Samsung',ram: '6GB', rom: '128GB', dispaly:'16.76 cm (6.6 inch) Full HD+ Display',battery: '5000 mAh Lithium-ion Battery', front_camera:'8MP' , back_camera:'64MP + 5MP + 2MP',
      //             image:"Samsung Galaxy F42 5G"  },
      //         { id: 14, name: 'SAMSUNG Galaxy Note 20 Ultra 5G ',color:'Mystic Bronze',  orginal_price:116000, selling_price:,offer:,description: 'Previous generation flagship', brand: 'Samsung',ram: '12GB', rom: '256GB', dispaly:'17.53 cm (6.9 inch) Quad HD+ Display',battery: '4500 mAh Lithium-ion Battery', front_camera:'10MP' , back_camera:'108MP + 12MP + 12MP',
      //             image:"SAMSUNG Galaxy Note 20 Ultra 5G "  },
      //         { id: 15, name: 'Samsung Galaxy S24 Ultra',color:'Phantom Black', orginal_price:142999, selling_price:1,42,999,offer:,description: 'Premium Samsung mobile', brand: 'Samsung' ,ram: '12GB', rom: '512GB', dispaly:'17.27 cm (6.8 inch) Quad HD+ Display',battery: '5000 mAh Lithium-ion Battery', front_camera:'40MP' , back_camera:'108MP + 12MP + 10MP + 10MP ',
      //             image:"Samsung Galaxy S24 Ultra" },
        // Apple Phones
        { id: 16, name: 'iPhone 13', price: 1099, description: 'Latest Apple mobile', brand: 'Apple',image:ip1  },
        { id: 17, name: 'iPhone 13 Mini', price: 699, description: 'Compact Apple mobile', brand: 'Apple',image:ip2  },
        { id: 18, name: 'iPhone 13 Pro', price: 999, description: 'High-end Apple mobile', brand: 'Apple' ,image:ip3 },
        { id: 19, name: 'iPhone 13 Pro Max', price: 1099, description: 'Largest Apple mobile', brand: 'Apple',image:ip4  },
        { id: 20, name: 'iPhone 12', price: 799, description: 'Previous generation Apple mobile', brand: 'Apple',image:ip5  },
        { id: 21, name: 'iPhone 12 Mini', price: 699, description: 'Compact previous generation Apple mobile', brand: 'Apple',image:ip6  },
        { id: 22, name: 'iPhone 12 Pro', price: 999, description: 'High-end previous generation Apple mobile', brand: 'Apple',image:ip7  },
        { id: 23, name: 'iPhone 12 Pro Max', price: 1099, description: 'Largest previous generation Apple mobile', brand: 'Apple',image:ip8  },
        { id: 24, name: 'iPhone SE (2022)', price: 429, description: 'Budget Apple mobile', brand: 'Apple',image:ip9  },
        { id: 25, name: 'iPhone 11', price: 699, description: 'Older generation Apple mobile', brand: 'Apple' ,image:ip10 },
        { id: 26, name: 'iPhone 11 Pro', price: 899, description: 'Premium older generation Apple mobile', brand: 'Apple' ,image:ip11 },
        { id: 27, name: 'iPhone 11 Pro Max', price: 999, description: 'Largest older generation Apple mobile', brand: 'Apple' ,image:ip12 },
        { id: 28, name: 'iPhone XS', price: 599, description: 'Older generation Apple mobile', brand: 'Apple',image:ip13  },
        { id: 29, name: 'iPhone XS Max', price: 699, description: 'Larger older generation Apple mobile', brand: 'Apple',image:ip14 },
        { id: 30, name: 'iPhone XR', price: 499, description: 'Budget older generation Apple mobile', brand: 'Apple',image:ip15  },
        
        // Xiaomi (Redmi) Phones
        { id: 31, name: 'Redmi Note 11', price: 299, description: 'Latest Redmi mobile', brand: 'Xiaomi',image:r1 },
        { id: 32, name: 'Redmi Note 11 Pro', price: 399, description: 'High-end Redmi mobile', brand: 'Xiaomi',image:r2  },
        { id: 33, name: 'Redmi Note 11 Pro+', price: 499, description: 'Premium Redmi mobile', brand: 'Xiaomi' ,image:r3 },
        { id: 34, name: 'Redmi K40', price: 349, description: 'Flagship Redmi mobile', brand: 'Xiaomi' ,image:r4 },
        { id: 35, name: 'Redmi K40 Pro', price: 399, description: 'High-end Redmi mobile', brand: 'Xiaomi' ,image:r5 },
        { id: 36, name: 'Redmi K40 Pro+', price: 499, description: 'Premium flagship Redmi mobile', brand: 'Xiaomi' ,image:r6 },
        { id: 37, name: 'Redmi 10', price: 199, description: 'Budget Redmi mobile', brand: 'Xiaomi',image:r7 },
        { id: 38, name: 'Redmi 10 Prime', price: 249, description: 'Enhanced budget Redmi mobile', brand: 'Xiaomi',image:r8  },
        { id: 39, name: 'Redmi 10X', price: 299, description: 'Mid-range Redmi mobile', brand: 'Xiaomi',image:r9 },
        { id: 40, name: 'Redmi 10X Pro', price: 349, description: 'High-end mid-range Redmi mobile', brand: 'Xiaomi' ,image:r10},
        { id: 41, name: 'Redmi Note 10', price: 299, description: 'Latest mid-range Redmi mobile', brand: 'Xiaomi',image:r11  },
        { id: 42, name: 'Redmi Note 10 Pro', price: 399, description: 'High-end mid-range Redmi mobile', brand: 'Xiaomi',image:r12 },
        { id: 43, name: 'Redmi Note 10 Pro Max', price: 449, description: 'Premium mid-range Redmi mobile', brand: 'Xiaomi',image:r13  },
        { id: 44, name: 'Redmi Note 9', price: 249, description: 'Previous generation Redmi mobile', brand: 'Xiaomi',image:r14 },
        { id: 45, name: 'Redmi Note 9 Pro', price: 299, description: 'High-end previous generation Redmi mobile', brand: 'Xiaomi',image:r15 },
        
        // Oppo Phones
        { id: 46, name: 'Oppo Find X5', price: 899, description: 'Latest Oppo mobile', brand: 'Oppo',image:o1  },
        { id: 47, name: 'Oppo Find X5 Pro', price: 1099, description: 'High-end Oppo mobile', brand: 'Oppo' ,image:o2 },
        { id: 48, name: 'Oppo Reno 7', price: 499, description: 'Mid-range Oppo mobile', brand: 'Oppo' ,image:o3 },
        { id: 49, name: 'Oppo Reno 7 Pro', price: 599, description: 'High-end Oppo mobile', brand: 'Oppo' ,image:o4 },
        { id: 50, name: 'Oppo A74', price: 349, description: 'Budget Oppo mobile', brand: 'Oppo' ,image:o5 },
        { id: 51, name: 'Oppo A74 5G', price: 399, description: '5G Oppo mobile', brand: 'Oppo' ,image:o6},
        { id: 52, name: 'Oppo A54', price: 299, description: 'Affordable Oppo mobile', brand: 'Oppo',image:o7  },
        { id: 53, name: 'Oppo A54 5G', price: 349, description: '5G budget Oppo mobile', brand: 'Oppo',image:o8 },
        { id: 54, name: 'Oppo A73', price: 249, description: 'Budget Oppo mobile', brand: 'Oppo',image:o9},
        { id: 55, name: 'Oppo F19', price: 349, description: 'Mid-range Oppo mobile', brand: 'Oppo',image:o10  },
        { id: 56, name: 'Oppo F19 Pro', price: 399, description: 'High-end Oppo mobile', brand: 'Oppo' ,image:o11 },
        { id: 57, name: 'Oppo F19 Pro+', price: 449, description: 'Premium Oppo mobile', brand: 'Oppo' ,image:o12 },
        { id: 58, name: 'Oppo Reno 6', price: 499, description: 'Latest mid-range Oppo mobile', brand: 'Oppo' ,image:o13 },
        { id: 59, name: 'Oppo Reno 6 Pro', price: 599, description: 'High-end mid-range Oppo mobile', brand: 'Oppo' ,image:o14 },
        { id: 60, name: 'Oppo Reno 6 Pro+', price: 699, description: 'Premium mid-range Oppo mobile', brand: 'Oppo' ,image:o15 },
        
        // Vivo Phones
        // { id: 61, name: 'Vivo X70', price: 799, description: 'Latest Vivo mobile', brand: 'Vivo' ,image:"https://fdn2.gsmarena.com/vv/pics/apple/apple-iphone-12-r1.jpg" },
        // { id: 62, name: 'Vivo X70 Pro', price: 999, description: 'High-end Vivo mobile', brand: 'Vivo' ,image:"https://fdn2.gsmarena.com/vv/pics/apple/apple-iphone-12-r1.jpg" },
        // { id: 63, name: 'Vivo X70 Pro+', price: 1199, description: 'Premium Vivo mobile', brand: 'Vivo' ,image:"https://fdn2.gsmarena.com/vv/pics/apple/apple-iphone-12-r1.jpg" },
        // { id: 64, name: 'Vivo V21', price: 499, description: 'Mid-range Vivo mobile', brand: 'Vivo' ,image:"https://fdn2.gsmarena.com/vv/pics/apple/apple-iphone-12-r1.jpg" },
        // { id: 65, name: 'Vivo V21e', price: 399, description: 'Affordable Vivo mobile', brand: 'Vivo',image:"https://fdn2.gsmarena.com/vv/pics/apple/apple-iphone-12-r1.jpg"  },
        // { id: 66, name: 'Vivo Y73', price: 349, description: 'Budget Vivo mobile', brand: 'Vivo' ,image:"https://fdn2.gsmarena.com/vv/pics/apple/apple-iphone-12-r1.jpg" },
        // { id: 67, name: 'Vivo Y73s', price: 399, description: 'High-end budget Vivo mobile', brand: 'Vivo',image:"https://fdn2.gsmarena.com/vv/pics/apple/apple-iphone-12-r1.jpg"  },
        // { id: 68, name: 'Vivo Y52s', price: 299, description: 'Affordable Vivo mobile', brand: 'Vivo',image:"https://fdn2.gsmarena.com/vv/pics/apple/apple-iphone-12-r1.jpg"  },
        // { id: 69, name: 'Vivo Y51', price: 249, description: 'Budget Vivo mobile', brand: 'Vivo',image:"https://fdn2.gsmarena.com/vv/pics/apple/apple-iphone-12-r1.jpg"  },
        // { id: 70, name: 'Vivo Y20', price: 199, description: 'Entry-level Vivo mobile', brand: 'Vivo',image:"https://fdn2.gsmarena.com/vv/pics/apple/apple-iphone-12-r1.jpg"  },
        // { id: 71, name: 'Vivo Y20s', price: 229, description: 'Enhanced budget Vivo mobile', brand: 'Vivo',image:"https://fdn2.gsmarena.com/vv/pics/apple/apple-iphone-12-r1.jpg"  },
        // { id: 72, name: 'Vivo Y21', price: 239, description: 'Affordable Vivo mobile', brand: 'Vivo' ,image:"https://fdn2.gsmarena.com/vv/pics/apple/apple-iphone-12-r1.jpg" },
        // { id: 73, name: 'Vivo S9', price: 499, description: 'Mid-range Vivo mobile', brand: 'Vivo',image:"https://fdn2.gsmarena.com/vv/pics/apple/apple-iphone-12-r1.jpg"  },
        // { id: 74, name: 'Vivo S9 Pro', price: 599, description: 'High-end Vivo mobile', brand: 'Vivo',image:"https://fdn2.gsmarena.com/vv/pics/apple/apple-iphone-12-r1.jpg"  },
        // { id: 75, name: 'Vivo S9e', price: 399, description: 'Budget high-end Vivo mobile', brand: 'Vivo' ,image:"https://fdn2.gsmarena.com/vv/pics/apple/apple-iphone-12-r1.jpg" },
        { id: 61, name: 'vivo Y18i', price: 799, description: 'Latest Vivo mobile', brand: 'Vivo' ,image:v1},
        { id: 62, name: 'Vivo X70 Pro', price: 999, description: 'High-end Vivo mobile', brand: 'Vivo' ,image:v2 },
        { id: 63, name: 'Vivo Y200 5G ', price: 1199, description: 'Premium Vivo mobile', brand: 'Vivo' ,image:v3 },
        { id: 64, name: 'vivo Y22', price: 499, description: 'Mid-range Vivo mobile', brand: 'Vivo' ,image:v4 },
        { id: 65, name: 'Vivo T3 Lite 5G Smartphone', price: 399, description: 'Affordable Vivo mobile', brand: 'Vivo',image:v5  },
        { id: 66, name: 'Vivo Y200e 5G', price: 349, description: 'Budget Vivo mobile', brand: 'Vivo' ,image:v6},
        { id: 67, name: 'vivo Y58 5G', price: 399, description: 'High-end budget Vivo mobile', brand: 'Vivo',image:v7},
        { id: 68, name: 'vivo Y28s 5G ', price: 299, description: 'Affordable Vivo mobile', brand: 'Vivo',image:v8 },
        { id: 69, name: 'Vivo V30e 5G Smartphone', price: 249, description: 'Budget Vivo mobile', brand: 'Vivo',image:v9},
        { id: 70, name: 'Vivo T2x 5G ', price: 199, description: 'Entry-level Vivo mobile', brand: 'Vivo',image:v10  },
        { id: 71, name: 'Vivo Y27 ', price: 229, description: 'Enhanced budget Vivo mobile', brand: 'Vivo',image:v11  },
        { id: 72, name: 'Vivo V29e 5G ', price: 239, description: 'Affordable Vivo mobile', brand: 'Vivo' ,image:v12 },
        { id: 73, name: 'vivo Y200 Pro 5G', price: 499, description: 'Mid-range Vivo mobile', brand: 'Vivo',image:v13 },
        { id: 74, name: 'vivo V29 Pro 5G', price: 599, description: 'High-end Vivo mobile', brand: 'Vivo',image:v14},
        { id: 75, name: 'Vivo X100 5G', price: 399, description: 'Budget high-end Vivo mobile', brand: 'Vivo' ,image:v15 },
        
        // Infinix Phones
        // { id: 76, name: 'Infinix Zero 8', price: 399, description: 'Latest Infinix mobile', brand: 'Infinix',image:"https://fdn2.gsmarena.com/vv/pics/apple/apple-iphone-12-r1.jpg"  },
        // { id: 77, name: 'Infinix Zero 8i', price: 349, description: 'Mid-range Infinix mobile', brand: 'Infinix',image:"https://fdn2.gsmarena.com/vv/pics/apple/apple-iphone-12-r1.jpg"  },
        // { id: 78, name: 'Infinix Note 10', price: 299, description: 'Budget Infinix mobile', brand: 'Infinix' ,image:"https://fdn2.gsmarena.com/vv/pics/apple/apple-iphone-12-r1.jpg" },
        // { id: 79, name: 'Infinix Note 10 Pro', price: 349, description: 'High-end budget Infinix mobile', brand: 'Infinix' ,image:"https://fdn2.gsmarena.com/vv/pics/apple/apple-iphone-12-r1.jpg"  },
        // { id: 80, name: 'Infinix Note 10 Pro Max', price: 399, description: 'Premium Infinix mobile', brand: 'Infinix' ,image:"https://fdn2.gsmarena.com/vv/pics/apple/apple-iphone-12-r1.jpg"  },
        // { id: 81, name: 'Infinix Hot 10', price: 249, description: 'Entry-level Infinix mobile', brand: 'Infinix' ,image:"https://fdn2.gsmarena.com/vv/pics/apple/apple-iphone-12-r1.jpg"  },
        // { id: 82, name: 'Infinix Hot 10S', price: 279, description: 'Enhanced entry-level Infinix mobile', brand: 'Infinix'  ,image:"https://fdn2.gsmarena.com/vv/pics/apple/apple-iphone-12-r1.jpg" },
        // { id: 83, name: 'Infinix Hot 11', price: 299, description: 'Budget Infinix mobile', brand: 'Infinix' ,image:"https://fdn2.gsmarena.com/vv/pics/apple/apple-iphone-12-r1.jpg"  },
        // { id: 84, name: 'Infinix Hot 11S', price: 349, description: 'High-end budget Infinix mobile', brand: 'Infinix' ,image:"https://fdn2.gsmarena.com/vv/pics/apple/apple-iphone-12-r1.jpg"  },
        // { id: 85, name: 'Infinix X6820', price: 399, description: 'Mid-range Infinix mobile', brand: 'Infinix' ,image:"https://fdn2.gsmarena.com/vv/pics/apple/apple-iphone-12-r1.jpg"  },
        // { id: 86, name: 'Infinix X6820 Pro', price: 449, description: 'High-end Infinix mobile', brand: 'Infinix'  ,image:"https://fdn2.gsmarena.com/vv/pics/apple/apple-iphone-12-r1.jpg" },
        // { id: 87, name: 'Infinix X6820 Pro Max', price: 499, description: 'Premium Infinix mobile', brand: 'Infinix' ,image:"https://fdn2.gsmarena.com/vv/pics/apple/apple-iphone-12-r1.jpg"  },
        // { id: 88, name: 'Infinix Zero Ultra', price: 599, description: 'Latest high-end Infinix mobile', brand: 'Infinix' ,image:"https://fdn2.gsmarena.com/vv/pics/apple/apple-iphone-12-r1.jpg"  },
        // { id: 89, name: 'Infinix Zero Ultra Pro', price: 699, description: 'Premium high-end Infinix mobile', brand: 'Infinix' ,image:"https://fdn2.gsmarena.com/vv/pics/apple/apple-iphone-12-r1.jpg"  },
        // { id: 90, name: 'Infinix Note 11 Pro', price: 349, description: 'Mid-range Infinix mobile', brand: 'Infinix'  ,image:"https://fdn2.gsmarena.com/vv/pics/apple/apple-iphone-12-r1.jpg" },
                // Infinix Phones
                { id: 76, name: 'Infinix Zero 8', price: 399, description: 'Latest Infinix mobile', brand: 'Infinix',image:i1  },
                { id: 77, name: 'Infinix Zero 8i', price: 349, description: 'Mid-range Infinix mobile', brand: 'Infinix',image:i2  },
                { id: 78, name: 'Infinix Hot 40i', price: 299, description: 'Budget Infinix mobile', brand: 'Infinix' ,image:i3 },
                { id: 79, name: 'Infinix Note 40 Pro 5G', price: 349, description: 'High-end budget Infinix mobile', brand: 'Infinix' ,image:i4  },
                { id: 80, name: 'Infinix GT 20 Pro', price: 399, description: 'Premium Infinix mobile', brand: 'Infinix' ,image:i5  },
                { id: 81, name: 'Infinix SMART 8 HD', price: 249, description: 'Entry-level Infinix mobile', brand: 'Infinix' ,image:i6  },
                { id: 82, name: 'Infinix Hot 30 5G', price: 279, description: 'Enhanced entry-level Infinix mobile', brand: 'Infinix'  ,image:i7 },
                { id: 83, name: 'Infinix Hot 11 pro', price: 299, description: 'Budget Infinix mobile', brand: 'Infinix' ,image:i8  },
                { id: 84, name: ' Infinix HOT 12', price: 349, description: 'High-end budget Infinix mobile', brand: 'Infinix' ,image:i9  },
                { id: 85, name: 'Infinix Smart 7 HD', price: 399, description: 'Mid-range Infinix mobile', brand: 'Infinix' ,image:i10 },
                { id: 86, name: 'Infinix Zero 30 5G', price: 449, description: 'High-end Infinix mobile', brand: 'Infinix'  ,image:i11 },
                { id: 87, name: 'Infinix Note 40 Pro+ 5G', price: 499, description: 'Premium Infinix mobile', brand: 'Infinix' ,image:i12  },
                { id: 88, name: 'Infinix Zero Ultra', price: 599, description: 'Latest high-end Infinix mobile', brand: 'Infinix' ,image:i13  },
                { id: 89, name: 'Infinix Hot 30i', price: 699, description: 'Premium high-end Infinix mobile', brand: 'Infinix' ,image:i14  },
                { id: 90, name: 'Infinix Note 30', price: 349, description: 'Mid-range Infinix mobile', brand: 'Infinix'  ,image:i15 },
        // Poco Phones
        { id: 91, name: 'Poco X4 Pro', price: 399, description: 'Latest Poco mobile', brand: 'Poco'  ,image:p1 },
        { id: 92, name: 'Poco X4', price: 299, description: 'Mid-range Poco mobile', brand: 'Poco'  ,image:p2},
        { id: 93, name: 'Poco M4 Pro', price: 349, description: 'High-end Poco mobile', brand: 'Poco' ,image:p3 },
        { id: 94, name: 'Poco M4', price: 249, description: 'Budget Poco mobile', brand: 'Poco' ,image:p4},
        { id: 95, name: 'Poco C4', price: 199, description: 'Entry-level Poco mobile', brand: 'Poco'  ,image:p5 },
        { id: 96, name: 'Poco F4', price: 499, description: 'Flagship Poco mobile', brand: 'Poco' ,image:p6  },
        { id: 97, name: 'Poco F4 GT', price: 599, description: 'Gaming Poco mobile', brand: 'Poco' ,image:p7  },
        { id: 98, name: 'Poco X3 Pro', price: 349, description: 'High-end Poco mobile', brand: 'Poco'  ,image:p8 },
        { id: 99, name: 'Poco X3', price: 299, description: 'Mid-range Poco mobile', brand: 'Poco' ,image:p9 },
        { id: 100, name: 'Poco X2', price: 249, description: 'Budget Poco mobile', brand: 'Poco' ,image:p10  },
        { id: 101, name: 'Poco M3', price: 199, description: 'Affordable Poco mobile', brand: 'Poco'  ,image:p11 },
        { id: 102, name: 'Poco M3 Pro', price: 249, description: 'Budget 5G Poco mobile', brand: 'Poco' ,image:p12  },
        { id: 103, name: 'Poco F3', price: 399, description: 'Flagship Poco mobile', brand: 'Poco' ,image:p13  },
        { id: 104, name: 'Poco F3 GT', price: 499, description: 'Gaming Poco mobile', brand: 'Poco'  ,image:p14 },
        { id: 105, name: 'Poco X3 GT', price: 399, description: 'High-end Poco mobile', brand: 'Poco' ,image:p15  },
    
        { id: 106, name: 'OnePlus 11', price: 999, description: 'Latest OnePlus mobile', brand: 'OnePlus' ,image:on1  },
        { id: 107, name: 'OnePlus 11T', price: 899, description: 'High-end OnePlus mobile', brand: 'OnePlus' ,image:on2 },
        { id: 108, name: 'OnePlus 10 Pro', price: 799, description: 'Premium OnePlus mobile', brand: 'OnePlus'  ,image:on3 },
        { id: 109, name: 'OnePlus 10', price: 699, description: 'Mid-range OnePlus mobile', brand: 'OnePlus',image:on4 },
        { id: 110, name: 'OnePlus 9 Pro', price: 949, description: 'High-end OnePlus mobile', brand: 'OnePlus' ,image:on5},
        { id: 111, name: 'OnePlus 9', price: 729, description: 'Flagship OnePlus mobile', brand: 'OnePlus',image:on6 },
        { id: 112, name: 'OnePlus 8T', price: 649, description: 'Latest OnePlus mobile', brand: 'OnePlus',image:on7 },
        { id: 113, name: 'OnePlus 8 Pro', price: 899, description: 'High-end OnePlus mobile', brand: 'OnePlus' ,image:on8},
        { id: 114, name: 'OnePlus 8', price: 599, description: 'Mid-range OnePlus mobile', brand: 'OnePlus' ,image:on9},
        { id: 115, name: 'OnePlus Nord 2', price: 499, description: 'Mid-range OnePlus mobile', brand: 'OnePlus',image:on10 },
        { id: 116, name: 'OnePlus Nord CE', price: 399, description: 'Affordable OnePlus mobile', brand: 'OnePlus' ,image:on11},
        { id: 117, name: 'OnePlus Nord', price: 349, description: 'Budget OnePlus mobile', brand: 'OnePlus' ,image:on12},
        { id: 118, name: 'OnePlus 7T Pro', price: 799, description: 'High-end older OnePlus mobile', brand: 'OnePlus',image:on13 },
        { id: 119, name: 'OnePlus 7T', price: 599, description: 'Mid-range older OnePlus mobile', brand: 'OnePlus' ,image:on14},
        { id: 120, name: 'OnePlus 7', price: 499, description: 'Budget older OnePlus mobile', brand: 'OnePlus',image:on15 }

    
      ],

    
};

  
  const productReducer = (state = initialState, action) => {
    switch (action.type) {
      default:
        return state;
    }
  };
  
  export default productReducer;
  