
import { initializeApp } from 'firebase/app';
import { getAuth, RecaptchaVerifier, signInWithPhoneNumber, PhoneAuthProvider } from 'firebase/auth';

const firebaseConfig = {
    apiKey: 'AIzaSyBOofz_ys7mDRcB6TnytAwLUlrI-OMyOwM',
    authDomain: 'mobileshop-4d605.firebaseapp.com',
    projectId: 'mobileshop-4d605',
    storageBucket: 'mobileshop-4d605.appspot.com',
    messagingSenderId: '1062201137560',
    appId: '1:1062201137560:web:4ea09e34d12218f53d33ed',
    measurementId: 'G-T0513D2923',
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export { RecaptchaVerifier, signInWithPhoneNumber, PhoneAuthProvider };
